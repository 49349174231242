<template>
  <div>

    <div :class="isMobile?'oneTextFourBox':'oneTextFour point'"  v-if="state">
      <div :class="isMobile?'oneSpanBox':'oneSpan'">{{ title }}</div>
      <div :class="isMobile?'twoSpanBox':'twoSpan'" v-if="arrow"></div>
      <div :class="isMobile?'threeSpanBox':'threeSpan'"  v-if="arrow"></div>
    </div>
    <div class="oneTextFour point" v-else>
      <div class="oneSpanCenter">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    arrow: {
      type: Boolean,
      default: true
    },
    state: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang="scss" scoped>
.oneTextFour {
  margin: auto;
  position: relative;
  width: 189px;
  height: 48px;
  background: #fe5e00;
  border-radius: 3px;

  .oneSpan {
    position: absolute;
    top: 14px;
    left: 39px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 21px;
    color: #ffffff;
  }

  .oneSpanCenter {height: 100%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 21px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .twoSpan {
    position: absolute;
    top: 24px;
    left: 133px;
    width: 24px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .threeSpan {
    position: absolute;
    top: 19px;
    left: 147px;
    width: 11px;
    height: 11px;
    border-right: 2px solid rgba(255, 255, 255, 0.8);
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
    transform: rotate(-45deg);
  }
}

.oneTextFourBox {
   margin: auto;
  position: relative;
  width: 1.65rem;
height: 0.42rem;
background: #FE5E00;
border-radius: 0.03rem;

  .oneSpanBox {
    position: absolute;
    top: 0.11rem;
    left: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 0.18rem;
    color: #ffffff;
  }

  .twoSpanBox {
    position: absolute;
    top: 0.2rem;
    left:1.15rem;
    width: 0.24rem;
    height: 0.02rem;
    background-color: rgba(255, 255, 255, 0.8)
  }

  .threeSpanBox {
    position: absolute;
    top: 0.16rem;
    left: 1.29rem;
    width: 0.11rem;
    height: 0.11rem;
    border-right: 0.02rem solid rgba(255, 255, 255, 0.8);
    border-bottom: 0.02rem solid rgba(255, 255, 255, 0.8);
    transform: rotate(-45deg);
  }
}


.point {
  cursor: pointer;
}
</style>
