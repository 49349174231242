<template>
  <div>
    <!-- 头部 -->
    <nav-header />
    <app-main />
    <app-footer></app-footer>
  </div>
</template>

<script>
import { NavHeader, AppMain, AppFooter } from './components'

export default {
  components: {
    NavHeader,
    AppMain,
    AppFooter
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped></style>