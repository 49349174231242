// main.js 或 app.js
function setRemUnit() {
  // 设计稿宽度
  const designWidth = 750;
  // 设置根元素的font-size基准值，这里假设1rem = 100px
  const baseFontSize = 100;
  // 计算当前屏幕宽度相对于设计稿宽度的比例
  const scale = document.documentElement.clientWidth / designWidth;
  // 设置根元素的font-size
  document.documentElement.style.fontSize = `${baseFontSize * scale}px`;
}

// 初始化设置
setRemUnit();

// 监听窗口大小变化
window.addEventListener('resize', setRemUnit);