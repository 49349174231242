<template>
  <div>
    <div :class="isMobile?'coreBox':'core'" >
      <div :class="isMobile?'servicesBox':'services'"  >{{ title }}</div>
      <div :class="isMobile?'englishIntroduceBox':'englishIntroduce'" >{{ englis }}</div>
      <div :class="isMobile?'plusBox':'plus'"></div>
      <el-button type="primary" v-if="isShow"></el-button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    englis: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.core {
  padding-top: 100px;
  clear: both;
  right: 0;
  .services {
    width: 100%;
    height: 42px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 45px;
    margin: 0 auto 16px;
    text-align: center;
  }

  .englishIntroduce {
    width: 100%;
    height: 19px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    opacity: 0.46;
    // margin: 40px auto;
    text-align: center;
  }

  .plus {
    width: 81px;
    height: 5px;
    background: #FE5E00;
    margin: 40px auto 86px;
  }
}

.coreBox {
  padding-top:0.4rem;
  clear: both;
  right: 0;
  .servicesBox {
    width: 100%;
    height: 0.33rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.33rem;
    margin: 0 auto 0.14rem 0;
    text-align: center;
  }

  .englishIntroduceBox {
    width: 100%;
    height: 0.19rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 0.17rem;
    opacity: 0.46;
    // margin: 40px auto;
    text-align: center;
  }

  .plusBox {
    width: 0.67rem;
    height: 0.04rem;
    background: #FE5E00;
    margin: 0.26rem auto 0.38rem;
  }
}
</style>