import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '蜂腾集团',
    component: Layout,
    children: [{
      path: '',
        component: resolve => require(['@/view/home'], resolve),
      name: '蜂腾集团',
    },
    {
      path: 'smartCloudWarehouse',
      component: () => import('@/view/smartCloudWarehouse'),
      name: '智慧云仓',
    },
    {
      path: 'digitalLogistics',
      component: () => import('@/view/digitalLogistics'),
      name: '数字物流',
    },
    {
      path: 'procurementSalesTrade',
      component: () => import('@/view/procurementSalesTrade'),
      name: '采销贸易',
    },
    {
      path: 'numericalIntelligenceSystem',
      component: () => import('@/view/numericalIntelligenceSystem'),
      name: '数智系统',
    },
    {
      path: 'aboutFengteng',
      component: () => import('@/view/aboutFengteng'),
      name: '关于封腾',
    },
    {
      path: 'warehouseInfo',
      component: () => import('@/view/warehouseInfo'),
      name: '分仓简介',
    },
    {
      path: 'warehouseEdit',
      component: () => import('@/view/warehouseEdit'),
      name: '分仓简介',
    },
    {
      path: 'realTimeInfo',
      component: () => import('@/view/realTimeInfo'),
      name: '蜂腾资讯',
    },
    {
      path: 'realTimeEdit',
      component: () => import('@/view/realTimeEdit'),
      name: '资讯详情',
    },
    ]
  },


]

const router = new VueRouter({
  routes,
  mode: 'history', // 去掉url中的#
})

router.afterEach(() => {
  Vue.nextTick(() => {
    window.scrollTo(0, 0)
  })
})


export default router
