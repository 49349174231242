import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/style.css';
import ElementUI from 'element-ui';
import echarts from 'echarts'
import 'echarts/map/js/china'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icons/index' // icon
// import './utils/rem.js';

import ModuleHead from '@/components/ModuleHead'
import ButtonStyle from '@/components/ButtonStyle'
import Collaborate from '@/components/Collaborate'
import PopUpButton from '@/components/PopUpButton'
import cooperativeFranchise from '@/components/cooperativeFranchise'
import communicationNeeds from '@/components/communicationNeeds'
import freeTrial from '@/components/freeTrial'
import './utils/rem.js'
import VueSeamlessScroll from 'vue-seamless-scroll';
Vue.prototype.$echarts = echarts
import axios from 'axios'  //引入 axios 异步请求工具 插件
Vue.prototype.$http = axios;
Vue.component('ModuleHead', ModuleHead)
Vue.component('ButtonStyle', ButtonStyle)
Vue.component('Collaborate', Collaborate)
Vue.component('PopUpButton', PopUpButton)
Vue.component('cooperativeFranchise', cooperativeFranchise)
Vue.component('communicationNeeds', communicationNeeds)
Vue.component('freeTrial', freeTrial)
Vue.use(VueSeamlessScroll);

function detectMob() {
  return window.innerWidth <= 768;
}
Vue.prototype.isMobile = detectMob()

window.addEventListener('resize', () => {
  Vue.prototype.isMobile = detectMob();
});



Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
