<template>
  <div>
    <div class="footer-container" v-if="!isMobile">
      <div class="footer-box">
        <div class="oneBox">
          <img src="@/assets/public/蜂腾集团.png" alt="" />
        </div>
        <div class="twoBox">
          <div class="title">
            <div class="titleOne" @click="toRouter('/')">蜂腾集团 |</div>
            <div class="titleOne" @click="toRouter('/smartCloudWarehouse')">
              智慧云仓 |
            </div>
            <div @click="toRouter('/digitalLogistics')">智慧物流</div>
          </div>
          <div class="title">
            <div class="titleOne" @click="toRouter('/procurementSalesTrade')">
              采销贸易 |
            </div>
            <div
              class="titleOne"
              @click="toRouter('/numericalIntelligenceSystem')"
            >
              数智系统 |
            </div>
            <div @click="toRouter('/aboutFengteng')">关于蜂腾</div>
          </div>
        </div>
        <div class="threeBOX">
          <div>咨询热线</div>
          <div>400-6280-680</div>
          <div>（24小时服务热线）</div>
        </div>
        <div class="foruBox">
          <div>总部地址</div>
          <div>山东省青岛市城阳区天安数码城22栋</div>
          <div>（邮政编码：266109）</div>
        </div>
        <div class="qrCode">
          <div class="one">
            <img src="@/assets/public/公众号二维码.png" alt="" />
            <div>蜂腾官方公众号</div>
          </div>
          <div class="two">
            <img src="@/assets/public/抖音二维码.png" alt="" />
            <div>蜂腾官方抖音号</div>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="onRecord">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >ICP备案号：鲁ICP备2022035411号-6</a
        >
      </div>
    </div>
    <div v-else class="footerStyle">
      <div class="logoStyle">
        <img src="@/assets/public/底部logo.png" alt="" />
      </div>
      <div class="concentStyle">
        <div class="title">
          <div @click="toRouter('/')">蜂腾集团</div>
          <div class="titleOne">|</div>
          <div @click="toRouter('/smartCloudWarehouse')">智慧云仓</div>
          <div class="titleOne">|</div>
          <div @click="toRouter('/digitalLogistics')">智慧物流</div>
        </div>
        <div class="title">
          <div @click="toRouter('/procurementSalesTrade')">采销贸易</div>
          <div class="titleOne">|</div>
          <div @click="toRouter('/numericalIntelligenceSystem')">数智系统</div>
          <div class="titleOne">|</div>
          <div @click="toRouter('/aboutFengteng')">关于蜂腾</div>
        </div>
      </div>
      <div class="cityStyle">
        <div class="hotline">咨询热线 （24小时服务热线）</div>
        <div class="nmbStyle">400-6280-680</div>
        <div class="hotline">总部地址 （邮政编码：266109）</div>
        <div class="address">山东省青岛市城阳区天安数码城22栋</div>
      </div>
      <div class="scanTheCode">
        <div class="officialAccount">
          <img src="@/assets/public/公众号.png" alt="" />
          <div>蜂腾官方公众号</div>
        </div>
        <div class="officialAccount">
          <img src="@/assets/public/抖音账号.png" alt="" />
          <div>蜂腾官方抖音号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  width: 100%;
  height: 311px;
  background-color: #041a37;
  font-size: 14px;
  color: #dfe1e3;

  .footer-box {
    width: 1335px;
    height: 255px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-bottom: 1px solid #1e314b;
    overflow: hidden;
    .oneBox {
      width: 184px;
      height: 108px;
      border-right: 1px solid #1e314b;
      img {
        width: 103px;
        height: 97px;
      }
    }
    .twoBox {
      width: 282px;
      height: 108px;
      border-right: 1px solid #1e314b;
      padding: 15px 0 0 35px;

      .title {
        display: flex;
        margin-bottom: 25px;
        cursor: pointer;
        .titleOne {
          margin-right: 10px;
        }
      }
    }
    .threeBOX {
      width: 214px;
      height: 108px;
      border-right: 1px solid #1e314b;
      padding: 8px 0 0 35px;
      div:nth-child(1) {
        margin-bottom: 12px;
      }
      div:nth-child(2) {
        margin-bottom: 12px;
        color: #fe5e00;
        font-size: 20px;
        font-weight: bold;
      }
      div:nth-child(3) {
        margin-left: -5px;
      }
    }
    .foruBox {
      width: 340px;
      height: 108px;
      border-right: 1px solid #1e314b;
      padding: 8px 0 0 35px;
      div:nth-child(1) {
        margin-bottom: 12px;
      }
      div:nth-child(2) {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: bold;
      }
      div:nth-child(3) {
        margin-left: -5px;
      }
    }
    .qrCode {
      display: flex;
      margin-top: 34px;
      .one {
        margin: 0 34px 0 50px;
        img {
          width: 113px;
          height: 112px;
          margin: 0 0 5px 0;
        }
        div {
          text-align: center;
        }
      }
      .two {
        img {
          width: 114px;
          height: 113px;
          margin: 0 0 5px 0;
        }
        div {
          text-align: center;
        }
      }
    }
  }
  .onRecord {
    height: 55px;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footerStyle {
  width: 100%;
  background: #041a37;
  padding: 0 0.32rem 0 0.36rem;
  .logoStyle {
    width: 100%;
    height: 1.36rem;
    border-bottom: 1px solid #435369;
    display: flex;
    align-items: center;
    img {
      width: 2.21rem;
      height: 0.52rem;
    }
  }
  .concentStyle {
    width: 100%;
    height: 2.1rem;
    border-bottom: 1px solid #435369;
    padding: 0.38rem 0 0.38rem 0.13rem;
    font-weight: 400;
    font-size: 0.36rem;
    color: #ffffff;
    .title {
      display: flex;
      margin-bottom: 0.45rem;
      .titleOne {
        margin: 0 0.45rem;
      }
    }
  }
  .cityStyle {
    width: 100%;
    height: 2.81rem;
    border-bottom: 1px solid #435369;
    padding: 0.45rem 0 0 0.13rem;
    .hotline {
      font-weight: 300;
      font-size: 0.22rem;
      color: #ffffff;
    }
    .nmbStyle {
      color: #fe5e00;
      font-size: 0.42rem;
      margin: 0.2rem 0 0.35rem 0;
    }
    .address {
      font-weight: bold;
      font-size: 0.33rem;
      color: #ffffff;
      margin-top: 0.2rem;
    }
  }
  .scanTheCode {
    width: 100%;
    height: 4.75rem;
    padding: 0.62rem 0.26rem 0 0.25rem;
    display: flex;
    justify-content: space-between;
    .officialAccount {
      img {
        width: 2.72rem;
        height: 2.72rem;
      }
      div {
        font-weight: 400;
        font-size: 0.34rem;
        color: #ffffff;
        margin: 0.25rem 0 0 0.1rem;
      }
    }
  }
}
</style>
