<template>
  <div>
    <div class="nav-header act" v-if="!isMobile">
      <div class="nav-content fx">
        <div class="box">
          <div class="nav-logo"></div>
        </div>
        <ul class="nav-main-item fx">
          <li
            v-for="item in NavList"
            :key="item.path"
            class="item"
            :class="{ selected: $route.path === item.path }"
            @mouseover="showDropdown = item.name"
            @mouseleave="showDropdown = null"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="headerBox" v-else>
      <div class="overlay" v-show="ulShow" @click="ulClick"></div>
      <div class="headerStyle">
        <div class="headerLeft"></div>
        <div class="headerRight" @click="ulClick">
          <img
            src="@/assets/public/导航未选中.png"
            alt=""
            v-if="!ulShow"
            class="imgOne"
          />
          <img src="@/assets/public/导航选中.png" alt="" v-else class="imgTwo" />
        </div>
        
        <transition name="fade">
        <ul class="ulStyle" v-show="ulShow">
          <li
            v-for="item in NavList"
            :key="item.path"
            class="liStyle"
            :class="{ selected: $route.path === item.path }"
            @click="navigate(item.path)"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
      </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ulShow: false,
      NavList: [
        { name: "蜂腾集团", path: "/" },
        { name: "智慧云仓", path: "/smartCloudWarehouse" },
        { name: "数字物流", path: "/digitalLogistics" },
        { name: "采销贸易", path: "/procurementSalesTrade" },
        { name: "数智系统", path: "/numericalIntelligenceSystem" },
        { name: "关于蜂腾", path: "/aboutFengteng" },
      ],
      showDropdown: null,
      scrollTop: 0,
    };
  },
  mounted() {
    // this.onScrollTop();
  },
  methods: {
    navigate(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
      this.hideUl();
    },
    hideUl() {
      this.ulShow = false;
    },
    ulClick() {
      this.ulShow = !this.ulShow;
    },
    onScrollTop() {
      window.addEventListener("scroll", () => {
        this.scrollTop = document.documentElement.scrollTop;
        if (document.documentElement.scrollTop != 0) {
          document.querySelector(".nav-header").classList.add("act");
        } else {
          document.querySelector(".nav-header").classList.remove("act");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.5s;

  .nav-content {
    width: 1350px;
    line-height: 40px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    // background: #ccc;
    .box {
      width: 380px;
      height: 59px;
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-logo {
        width: 100%;
        height: 31px;
        background-color: pink;
        background: url("@/assets/public/蜂腾集团横板.png") no-repeat center;
        background-size: 100% 100%;
      }
    }

    .nav-main-item {
      .item {
        transition: all 0.3s;
        padding: 0 24px;
        color: #fff;
        position: relative;

        a:hover {
          cursor: pointer;
          color: #fe5f0e;
          // border-bottom: 1px solid #fe5f0e;
        }

        .dropdown-content {
          position: absolute;
          top: 41px;
          left: 15px;
          padding: 0 10px;
          opacity: 0;
          transition: all 1s;
        }
      }

      .selected {
        border-bottom: 1px solid #fe5f0e;
      }
    }
  }
}

.act,
.nav-header:hover {
  background: #fff;
  transition: all 0.5s;
  .box {
    width: 380px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-logo {
      width: 380px !important;
      height: 31px !important;
      background: url("@/assets/public/蜂腾集团横板黑色.png") no-repeat center !important;
      background-size: 100% 100% !important;
    }
  }

  .item {
    .dropdown-content {
      opacity: 1 !important;
      background: #fff;
      transition: all 1s;
    }

    a {
      color: #000 !important;
      transition: all 0.5s;
    }
  }
}
.headerBox {
  width: 100%;
  height: 1.2rem;
  .headerStyle {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1.2rem;
    background: #fff;
    padding: 0.2rem 0.2rem 0 0.2rem;
    z-index: 10001;
    .headerLeft {
      width: 3.8rem;
      height: 0.31rem;
      background: url("@/assets/public/首页logo.png") no-repeat center;
      background-size: 100% 100%;
    }
    .headerRight {
      .imgOne {
        width: 0.59rem;
        height: 0.44rem;
      }
      .imgTwo {
        width: 0.44rem;
        height: 0.59rem;
      }
    }
    .ulStyle {
      position: absolute;
      top: 1.2rem;
      left: 0;
      height: 6rem;
      width: 100%;
      background-color: #fff;
      z-index: 999;
      font-weight: 400;
      font-size: 0.31rem;
      .liStyle {
        width: 100%;
        height: 1rem;
        border-bottom: 1px solid #e6e9eb;
        line-height: 1rem;
        padding-left: 0.33rem;
        a {
          color: #010101 !important;
          transition: all 0.5s;
        }
      }
      .selected a {
        color: #fe5f0e !important;
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 10; 
}
</style>
