<template>
  <div>
    <transition name="fade" mode="out-in">
      <router-view :key="key"></router-view>
    </transition>
    <div>
      <div class="regular" v-if="!isMobile">
        <div class="item">
          <img src="@/assets/public/电话咨询.png" alt="" class="img" />
          <img
            src="@/assets/public/电话咨询-选中.png"
            alt=""
            class="Selected"
          />
          <div class="title">电话咨询</div>
          <div class="account3 point">
            <div>400 6280 680</div>
            <div>蜂腾全国服务热线</div>
            <div></div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/public/浮窗二维码.png" alt="" class="img" />
          <img
            src="@/assets/public/浮窗二维码-选中.png"
            alt=""
            class="Selected"
          />
          <div class="title">公众号</div>
          <div class="account2 point">
            <div>
              <img src="@/assets/public/公众号二维码.png" alt="" />
            </div>
          </div>
        </div>

        <div class="item" @click="backToTop">
          <img src="@/assets/public/置顶.png" alt="" class="img" />
          <img src="@/assets/public/置顶-选中.png" alt="" class="Selected" />
        </div>
      </div>
      <div v-else class="navigation">
        <div class="one" @click="ulClick">
          <img src="@/assets/public/电话.png" alt="" />
          <div class="telephone" v-show="ulShow">
            <div class="telephoneOne">咨询热线 （24小时服务热线）</div>
            <div class="telephonetwo">400-6280-680</div>
          </div>
        </div>
        <div class="two" @click="scrollToTop">
          <img src="@/assets/public/置顶1.png" alt="" />
        </div>
      </div>
    </div>
    <Collaborate ref="Collaborate" :source="false"></Collaborate>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ulShow: false,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {
    this.collaborateOpen();
  },
  methods: {
    ulClick() {
      this.ulShow = !this.ulShow;
    },
    collaborateOpen() {
      setTimeout(() => {
        this.$refs.Collaborate.inio();
      }, 30000);
    },
    backToTop() {
      var timer = setInterval(function () {
        var left = window.pageYOffset;
        var step = Math.ceil((left - 0) / 10);
        window.scroll(0, left - step);
        if (left == 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
};
</script>

<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.regular {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 200px;
  right: 20px;
  width: 65px;
  height: 80px;
  z-index: 10;
  font-size: 12px;

  .item {
    background: #e6e6e6;
    padding: 5px 0;
    margin: 1px 0;
    cursor: pointer;
    position: relative;

    .img {
      display: block;
      margin: 0 auto 5px;
      opacity: 1;
    }

    .Selected {
      opacity: 0;
      display: none;
      margin: 0 auto 5px;
    }

    .title {
      text-align: center;
    }

    .account2 {
      display: none;
      position: absolute;
      top: -30px;
      right: 75px;
      opacity: 0;
    }

    .account3 {
      display: none;
      position: absolute;
      top: 0;
      right: 75px;
      color: #000;
      width: 160px;
      height: 53px;
      opacity: 0;

      background-color: #fff;

      div:nth-child(1) {
        font-size: 20px;
        font-family: Fiolex Girls;
        color: #fe5f0e;
        text-align: center;
        line-height: 25px;
      }

      div:nth-child(2) {
        font-size: 14px;
        font-family: Source Han Sans CN;
        color: #ccc;
        margin-top: 3px;
        text-align: center;
        line-height: 20px;
      }

      div:nth-child(3) {
        position: absolute;
        top: 20px;
        right: -8px;
        width: 0;
        height: 0;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }
  }

  .item:hover {
    background: #fe5e00;
    transition: all 0.3s;

    .account2 {
      display: block;
      opacity: 1;
      transition: all 1s;
    }

    .account3 {
      display: block;
      opacity: 1;
      transition: all 1s;
    }

    .img {
      display: none;
      opacity: 0;
      transition: all 1s;
    }

    .Selected {
      display: block;
      opacity: 1;
      transition: all 1s;
    }

    .title {
      color: #fff;
    }
  }
}
.navigation {
  position: fixed;
  bottom: 1rem;
  right: 0.26rem;
  z-index: 999;
  .one {
    width: 1.35rem;
    height: 1.21rem;
    background: #fe5e00;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      width: 0.49rem;
      height: 0.47rem;
    }
    .telephone {
      position: absolute;
      top: 0;
      right: 1.21rem;
      width: 4.06rem;
      height: 1.21rem;
      background-color: #fe5e00;
      color: #fff;
      padding: 0.26rem 0 0 0.32rem;
      z-index: 999;
      .telephoneOne {
        font-size: 0.26rem;
      }
      .telephonetwo {
        font-size: 0.49rem;
        font-weight: bold;
        margin-top: 0.05rem;
      }
    }
  }
  .two {
    width: 1.35rem;
    height: 1.21rem;
    background: #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.11rem;
    img {
      width: 0.49rem;
      height: 0.47rem;
    }
  }
}
</style>
